import BaseService from "./base.service";

class CountryService {
    getcountries(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('countries', page, params);
    }
    getAmenities() {
            return BaseService.apiGetRequest('amenities');
    }
    getRoomtype() {
        return BaseService.apiGetRequest('roomtype');
    }
    getRoomfloor() {
        return BaseService.apiGetRequest('roomfloor');
    }
    getFlooring() {
        return BaseService.apiGetRequest('flooring');
    }
    getInfluences() {
        return BaseService.apiGetRequest('influences');
    }
    getListingsgoods() {
        return BaseService.apiGetRequest('listingsgoods');
    }
    getBasementdev() {
        return BaseService.apiGetRequest('basementdev');
    }
    getBasementtype(){
        return BaseService.apiGetRequest('basementtype');
    }
    
    getStates(page = null,search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('states', page, params);
    }
    getCities(page = null,search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('cities', page, params);
    }
}
export default new CountryService();
