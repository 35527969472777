import BaseService from "./base.service";

class BuildingService {
    buildings(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('building', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('building/'+id);
    }
    delete(building) {
        return BaseService.apiPostRequest('building/delete', building);
    }
    add(building) {
        return BaseService.apiPostRequest('building/add', building);
    }
    uploadimage(building) {
        return BaseService.apiPostRequest('building/uploadimage', building);
    }
    update(id, building) {
        return BaseService.apiPostRequest('building/'+id, building);
    }

    search(page=null) {
        return BaseService.apiGetRequest('building/search', page);
    }
    getbuildinglist() {
        return BaseService.apiGetRequest('buildinglist');
    }

}

export default new BuildingService();
