import BaseService from "./base.service";

class ListingsService {
    listings(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('listings', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('listing/'+id);
    }
    delete(listing) {
		return BaseService.apiPostRequest('listing/delete/', listing);
    }
    add(listing) {
        return BaseService.apiPostRequest('listing/add', listing);
    }
    uploadimage(listing) {
        return BaseService.apiPostRequest('listing/uploadimage', listing);
    }
    uploadpdf(listing) {
        return BaseService.apiPostRequest('listing/uploadpdf', listing);
    }
    update(id, listing) {
        return BaseService.apiPostRequest('listing/'+id, listing);
    }
    search(page=null) {
        return BaseService.apiGetRequest('listing/search', page);
    }
    getlistinglist() {
        return BaseService.apiGetRequest('listinglist');
    }
}

export default new ListingsService();